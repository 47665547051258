import React from 'react';
import LoginDiscord from './LoginDiscord';
import LoginLegacy from './LoginLegacy';

const Login: React.FC<{ setToken: (token: string) => void }> = ({ setToken }) => {
    return (
        <div className="lobby">
            <LoginDiscord setToken={setToken} />
            <p>or...</p>
            <LoginLegacy setToken={setToken} />
        </div>
    )
}

export default Login;
