import Card from "./Card";

interface Value
{
    [key: string]: number;
}

interface Index
{
    [key: number]: string;
}

export const Suits: Value = {
    "♠️": 0,
    "♣️": 1,
    "♦️": 2,
    "♥️": 3,
    "💩": 4,
    "🖕": 5,
    "👻": 6,
    "🤬": 7,
    "🧟‍♂️": 8,
    "🍄": 9,
};

export const SuitsByValue: Index = {
    0: "♠️",
    1: "♣️",
    2: "♦️",
    3: "♥️",
    4: "💩",
    5: "🖕",
    6: "👻",
    7: "🤬",
    8: "🧟‍♂️",
    9: "🍄",
};

export const Ranks: Value = {
    3: 0,
    4: 1,
    5: 2,
    6: 3,
    7: 4,
    8: 5,
    9: 6,
    10: 7,
    J: 8,
    Q: 9,
    K: 10,
    A: 11,
    2: 12,
};

const RanksByValue: Index = {
    0: "3",
    1: "4",
    2: "5",
    3: "6",
    4: "7",
    5: "8",
    6: "9",
    7: "10",
    8: "J",
    9: "Q",
    10: "K",
    11: "A",
    12: "2",
};

export interface Suit
{
    name: string;
    value: number;
}

export interface Rank
{
    name: string;
    value: number;
}

export function makeCard(value: number): Card
{
    const suit = value % 10;
    const rank = (value - suit) / 10;
    return new Card(RanksByValue[rank], SuitsByValue[suit]);
}
