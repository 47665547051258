import Crypto from 'crypto';
import Cookies from 'js-cookie';
import { LEGACY_AUTHENTICATION_PASSWORD, DEPRECATED_LEGACY_AUTHENTICATION_PASSWORD } from './constants'

export function hash(value: string): string
{
    return Crypto.createHash('sha1').update(value).digest('hex');
}

function generatePassword(): string
{
    const time = new Date().toISOString();
    const salt = Math.random().toString();
    return hash(time + salt);
}

export function getLegacyPassword(): string
{
    let password = Cookies.get(LEGACY_AUTHENTICATION_PASSWORD)

    if (password) {
        return password
    }

    password = Cookies.get(DEPRECATED_LEGACY_AUTHENTICATION_PASSWORD)

    if (password) {
        Cookies.remove(DEPRECATED_LEGACY_AUTHENTICATION_PASSWORD)
    } else {
        password = generatePassword()
    }

    Cookies.set(LEGACY_AUTHENTICATION_PASSWORD, password, { expires: 365, sameSite: 'strict' })

    return password
}

export default Crypto;
