import { Action } from './index';

export const USERS_FETCHED = 'USERS_FETCHED';
export const USER_WIN = 'USER_WIN';

export interface User
{
    wins: number
}

interface State
{
    [id: string]: User
}

export default function(state: State = {}, action: Action): State
{
    if (action.type === USERS_FETCHED) {
        return action.payload;
    }

    if (action.type === USER_WIN) {
        const id = action.payload;
        const user: User = state[id] || {
            wins: 0,
        };
        user.wins++;

        return {
            ...state,
            [id]: user,
        }
    }

    return state;
}
