import { combineReducers } from "redux";
import user from './user';
import users from './users';
import table from './table';
import bombs from './bombs';

export interface Action
{
    type: string;
    payload: any;
}

export default combineReducers({ user, users, table, bombs });
