import Rule from '../rules/Rule';
import Knock from '../exceptions/Knock';
import Cards from './Cards';

interface PlayerData
{
    id: string
    username: string
    avatar_url?: string
}
export default class Player
{
    public readonly id: string;
    public readonly username: string;
    public readonly avatar_url: string|undefined = undefined;
    public hand: Cards = new Cards();
    public current: boolean = false;
    public knocked: boolean = false;
    public up: boolean = false;
    public winner: boolean = false;
    public wins: number = 0;
    public disconnected: boolean = false;
    public bot: boolean = false;

    public constructor({ id, username, avatar_url }: PlayerData)
    {
        this.id = id;
        this.username = username;
        this.avatar_url = avatar_url;
    }

    public play = (rule: Rule): Cards =>
    {
        const selected = this.hand.selected();

        if (rule.allows(selected)) {
            this.hand.delete(selected);
            return selected;
        }

        throw new Knock(this);
    }

    public isOutOfCards = (): boolean =>
    {
        return this.hand.size() === 0;
    }
}
