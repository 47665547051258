import Pusher, { Channel } from 'pusher-js';
import React, { useEffect, useState } from "react";
import Announce, { CLIENT_ANNOUNCE } from '../pusher/events/active/Announce';
import { CLIENT_QUERY } from "../pusher/events/active/Query";

function makeTablePath(table: string): string {
    return "/tables/" + encodeURIComponent(table)
}

function formatTableName(table: string): string {
    const MAX_LENGTH = 21;

    if (table.length < MAX_LENGTH) {
        return table;
    }

    return table.substring(0, MAX_LENGTH) + "...";
}

const ActiveTables: React.FC<{pusher: Pusher}> = ({ pusher }) => {
    const [tables, setTables] = useState<string[]>([]);

    useEffect(() => {
        let mounted = true

        const channel = pusher.subscribe('private-active-tables') as Channel;

        channel.bind('pusher:subscription_succeeded', () => {
            channel.trigger(CLIENT_QUERY, {});
        });

        channel.bind(CLIENT_ANNOUNCE, ({ table_name }: Announce) => {
            if (tables.includes(table_name)) {
                return;
            }

            if (mounted) {
                setTables(tables.concat([table_name]))
            }
        });

        return () => { mounted = false }
    }, [tables, pusher]);

    if (tables.length === 0) {
        return <></>
    }

    return (
        <div className="active-tables">
            <h2>Active Tables</h2>
            <ul>
                {tables.map(table =>
                   <li key={table}><a href={makeTablePath(table)}>{formatTableName(table)}</a></li>
                )}
            </ul>
        </div>
    )
}

export default ActiveTables;
