import Cookies from 'js-cookie';
import { hash } from '../utils/Crypto';
import Dealer from './Dealer';
import Player from './Player';
import Game from './Game';
import HiddenCards from './HiddenCards';
import Deal from '../pusher/events/Deal';
import { makeCardsFromValues } from './Cards';
import Response from '../pusher/events/restore/Response';

export default class Table
{
    protected dealer: Dealer = new Dealer();
    public readonly players: Player[] = [];
    public game: Game|undefined;
    public readonly name: string;
    public readonly nameHash: string;
    public readonly user: Player;
    public restoring: boolean = false;

    public constructor(name: string, user: Player)
    {
        this.name = name;
        this.nameHash = hash(name);
        this.user = user;
    }

    public join = (newbie: Player): void =>
    {
        const index = this.players.findIndex(player => player.id > newbie.id);
        if (index === -1) {
            this.players.push(newbie);
        } else {
            this.players.splice(index, 0, newbie);
        }
    }

    public leave = (id: string): void =>
    {
        this.players.splice(this.players.findIndex(player => player.id === id), 1);
    }

    public find = (id: string): Player =>
    {
        return this.players[this.players.findIndex(player => player.id === id)];
    }

    public triggerDealer = (): void =>
    {
        fetch(process.env.REACT_APP_DEALER_ENDPOINT as string + `?table=${this.nameHash}`);
    }

    public deal = (event: Deal): void =>
    {
        this.user.hand = makeCardsFromValues(event.hand);

        this.clear();
        const starter = this.find(event.starter_id);
        starter.up = true;

        this.hideOthersCards();

        this.game = new Game([...this.players], event.game_id);
    }

    public tick = (): void =>
    {
        if (this.game) {
            this.game.tick();
        }

        this.save();
    }

    public restore = (): void =>
    {
        this.restoring = true;

        const json = Cookies.get('table-' + this.nameHash);

        if (!json) {
            return;
        }

        const data = JSON.parse(json);
        this.user.hand = makeCardsFromValues(data.user.hand);
    }

    public sync = (event: Response): void =>
    {
        if (!this.restoring) {
            return;
        }

        const gamers: Player[] = [];

        event.table.game.players.forEach(p => {
            let player = this.find(p.id);

            if (!player) {
                player = new Player({...p});
                this.join(player);
            }

            if (player.id !== this.user.id) {
                player.hand = new HiddenCards(p.hand.length);
            }

            player.current = p.current;
            player.knocked = p.knocked;
            player.up = p.up;
            player.winner = p.winner;
            player.wins = p.wins;
            player.disconnected = p.disconnected;

            gamers.push(player);
        });

        this.game = new Game(gamers, event.table.game.id);
        this.game.sync(event);
        this.restoring = false;
    }

    protected save = (): void =>
    {
        Cookies.set('table-' + this.nameHash,  JSON.stringify({
            user: {
                hand: this.user.hand,
            },
            game: {
                id: this.game?.id,
            },
        }), { expires: 1/24, sameSite: 'strict' });
    }

    protected clear = (): void =>
    {
        this.players.forEach(player => {
            player.up = false;
            player.knocked = false;
            player.current = false;
            player.winner = false;
        });
    }

    protected hideOthersCards = (): void =>
    {
        this.players.forEach(player => {
            if (player.id !== this.user.id) {
                player.hand = new HiddenCards(13);
            }
        });
    }
}
