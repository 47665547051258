import React, { useState, useEffect } from 'react';
import Pusher, { PresenceChannel } from 'pusher-js';
import { useSelector, useDispatch } from 'react-redux';
import { slide as Menu } from 'react-burger-menu';
import Cookies from 'js-cookie';
import Model from '../models/Table';
import { SuitsByValue } from '../models/Deck';
import Loader from './Loader';
import Hand from './Hand';
import Card from './Card';
import PlayButton from './PlayButton';
import DarkModeButton from './DarkModeButton';
import Avatar from './Avatar'
import Opponent from '../models/Opponent';
import History from './History';
import Deal from '../pusher/events/Deal';
import Play from '../pusher/events/Play';
import Request from '../pusher/events/restore/Request';
import Member from '../pusher/models/Member';
import ClientMetaData from '../pusher/models/ClientMetaData';
import Cards, { makeCardsFromValues } from '../models/Cards';
import Response from '../pusher/events/restore/Response';
import { CARDS_PLAYED } from '../redux/reducers/table';
import { TOGGLE_BOT } from '../redux/reducers/user';
import './Table.css';
import { Action } from '../redux/reducers';
import { USER_WIN } from '../redux/reducers/users';
import Announce, { CLIENT_ANNOUNCE } from '../pusher/events/active/Announce';
import PlayerStatus, { CLIENT_PLAYER_STATUS } from '../pusher/events/PlayerStatus';
import { CLIENT_QUERY } from '../pusher/events/active/Query';
import { API_TOKEN } from '../utils/constants';
import LogoutButton from './LogoutButton';
import fire from '../img/fire.gif'
const bomb = require('../audio/bomb1.mp3'); // wtf import doesn't work 😕

export interface Props
{
    table: Model;
    pusher: Pusher;
}

function spanWrap(suit: string)
{
    return <span key={suit} className={suit}>{suit}</span>;
}

function makeSuitString(count: number)
{
    const suits = [];

    for (let i = 0; i < count; i++) {
        suits.push(spanWrap(SuitsByValue[i]));
    }

    return suits;
}

function goBot(table: Model, pusher: Pusher, setPlayers: Function): void
{
    if (table.user.bot && table.user.up) {
        table.user.hand.min().select(true);
        const cards = table.user.hand.selected();
        const play: Play = {
            cards: cards.keys(),
            game_id: table.game?.id || '🙃',
        }
        pusher.channel('presence-table-' + table.nameHash).trigger('client-play', play);
        table.tick();
        setPlayers([...table.players]);
    }
}

function pruneDisconnectedPlayers(table: Model): void
{
    table.players.forEach(player => {
        if (player.disconnected) {
            table.leave(player.id);
        }
    });
}

const Table: React.FC<Props> = ({ table, pusher }) =>
{
    const dispatch = useDispatch();
    const bombs = useSelector((state: any) => state.bombs.count);
    const [players, setPlayers] = useState(table.players);
    const [dealing, setDealing] = useState(false);
    const winner = table.game?.getWinner();

    useEffect(() => {
        if (!bombs) {
            return
        }

        document.body.classList.toggle('bombed', true);
        setTimeout(() => {
            document.body.classList.toggle('bombed', false);
        }, 7000)

        const audioEl: HTMLMediaElement = document.getElementById("bomb-track") as HTMLMediaElement
        audioEl.play()
    }, [bombs])

    useEffect(() => {
        const tableChannel = pusher.channel('presence-table-' + table.nameHash) as PresenceChannel;
        tableChannel.bind('pusher:member_added', (member: Member) => {
            const opponent = table.find(member.id);
            if (!opponent) {
                table.join(new Opponent({ id: member.id, ...member.info }));
            } else {
                opponent.disconnected = false;
            }
            setPlayers([...table.players]);
        });
        tableChannel.bind('pusher:member_removed', (member: Member) => {
            table.find(member.id).disconnected = true;
            setPlayers([...table.players]);
        });
        tableChannel.bind('client-play', ({ cards, game_id }: Play, meta: ClientMetaData) => {
            if (table.user.id === meta.user_id) {
                table.user.hand.unselectAll()
                cards.forEach(value => {
                    table.user.hand.get(value).select(true);
                })
            } else {
                const opponent = table.find(meta.user_id) as Opponent;
                opponent.cards = makeCardsFromValues(cards);
            }

            table.tick();

            goBot(table, pusher, setPlayers);

            if (table.game?.id && table.game.id !== game_id) {
                console.warn(`no match for game "${game_id}", expected "${table.game?.id}"`);
                table.user.hand = new Cards();
                table.restoring = true;
                tableChannel.trigger('client-restore-request', {} as Request);
            }

            setPlayers([...table.players]);
            dispatch({ type: CARDS_PLAYED, payload: cards });
        });
        tableChannel.bind('client-dealing', (event: any, meta: ClientMetaData) => {
            setDealing(true);
            console.log('dealer: ' + (table.find(meta.user_id)?.username || meta.user_id));
        });

        tableChannel.bind('client-restore-response', (event: Response) => {
            table.sync(event);
            setPlayers([...table.players]);
        });

        if (table.restoring) {
            tableChannel.trigger('client-restore-request', {} as Request);
        }

        tableChannel.bind('client-restore-request', (event: Request) => {
            if (!table.game) {
                return;
            }

            const response: Response = {
                table: {
                    game: JSON.parse(JSON.stringify(table.game)),
                },
            };
            tableChannel.trigger('client-restore-response', response);
        });

        tableChannel.bind(CLIENT_PLAYER_STATUS, (playerStatus: PlayerStatus, meta: ClientMetaData) => {
            const player = table.players.find(player => player.id === meta.user_id)

            if (!player) {
                return
            }

            player.bot = playerStatus.bot
            setPlayers([...table.players]);
        })

        const privateChannel = pusher.subscribe('private-user-' + table.user.id);
        privateChannel.bind('deal-' + table.nameHash, (event: Deal) => {
            pruneDisconnectedPlayers(table);
            table.deal(event);
            goBot(table, pusher, setPlayers);
            setPlayers([...table.players]);
            setDealing(false);
        });

        const activeTablesChannel = pusher.subscribe('private-active-tables');
        activeTablesChannel.bind('pusher:subscription_succeeded', () => {
            activeTablesChannel.trigger(CLIENT_ANNOUNCE, {
                table_name: table.name,
            } as Announce);
        });
        activeTablesChannel.bind(CLIENT_QUERY, () => {
            activeTablesChannel.trigger(CLIENT_ANNOUNCE, {
                table_name: table.name,
            } as Announce);
        });
    }, [table, pusher, dispatch]);

    useEffect(() => {
        if (winner) {
            dispatch({ type: USER_WIN, payload: winner.id } as Action);

            fetch(process.env.REACT_APP_STORAGE_ENDPOINT as string, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: 'Bearer ' + Cookies.get(API_TOKEN),
                },
                body: JSON.stringify({
                    table: {
                        hash: table.nameHash,
                        name: table.name,
                    },
                    game: table.game,
                })
            });
        }
    }, [winner, table, dispatch]);

    if (dealing) {
        return <Loader />;
    }

    const dealButton = (
        <button className="deal" onClick={() => {
            setDealing(true);
            pusher.channel('presence-table-' + table.nameHash).trigger('client-dealing', {});
            table.triggerDealer();
        }}>
            <span role="img" aria-label="leave">💥</span> Deal
        </button>
    );

    const actionButton = (!!table.game?.getWinner() || !table.game)
        ? dealButton
        : <PlayButton table={table} pusher={pusher} setPlayers={setPlayers} />;

    const userIndex = players.indexOf(table.user);
    const opponentsBeforeUser = players.slice(0, userIndex);
    const opponentsAfterUser = players.slice(userIndex + 1);

    const toggleBotMode = () => {
        table.user.bot = !table.user.bot;
        table.user.hand.unselectAll();
        dispatch({ type: TOGGLE_BOT, payload: table.user.bot });

        pusher.channel('presence-table-' + table.nameHash)
            .trigger(CLIENT_PLAYER_STATUS, {
                bot: table.user.bot,
            } as PlayerStatus);

        setPlayers([...table.players]);
    }

    const botButtonClasses = table.user.bot
        ? "bot active"
        : "bot"

    const bombStyle = {
        backgroundImage: `url(${fire})`,
        padding: '25px',
        backgroundSize: 'contain',
    }

    const currentStyle = table.game?.current.cards.isBomb()
        ? bombStyle
        : {}

    return (
        <div>
            <Menu customBurgerIcon={ <span role="img" aria-label="menu">🍔</span> } disableAutoFocus>
                <div className="controls">
                    <LogoutButton />
                    <a href="/"><button className="leave"><span role="img" aria-label="leave">😴</span> Leave</button></a>
                    {dealButton}
                    <a href="https://leaderboard.tienlen.org" target="_blank" rel="noopener noreferrer"><button className="leaderboard"><span role="img" aria-label="leaderboard">🏆</span> Leaderboard</button></a>
                    <a href="https://tienlen.org" target="_blank" rel="noopener noreferrer"><button className="rules"><span role="img" aria-label="rules">👮</span> Rules</button></a>
                    <button className={botButtonClasses} onClick={toggleBotMode}>
                        <span role="img" aria-label="enable robot">🤖</span> Bot Mode
                    </button>
                    <DarkModeButton />
                </div>
            </Menu>
            <h1>{table.name} {makeSuitString(table.game?.players.length || 0)}</h1>
            <div className={"current" + (table.game?.getWinner() ? " winner pulsate-fwd" : "")} style={currentStyle}>
                <div className="player"><Avatar player={table.game?.current.player} /><span>{table.game?.current.player.username}</span></div>
                <div className="cards">
                    {table.game?.current.cards.toArray().map((card, index) => <Card key={index} {...card}/>)}
                </div>
            </div>
            <hr/>
            <Hand player={table.user} />
            {actionButton}
            <div className="table">
                {opponentsAfterUser.map((player, index) => <Hand key={index} player={player} />)}
                {opponentsBeforeUser.map((player, index) => <Hand key={index} player={player} />)}
            </div>
            <History game={table.game} />
            <audio id="bomb-track">
                <source src={bomb}></source>
            </audio>
        </div>
    )
}

export default Table
