import Cards from '../models/Cards';
import Rule from './Rule';

export default class Bomb implements Rule
{
    protected cards: Cards;

    public constructor(cards: Cards)
    {
        this.cards = cards;
    }

    public allows = (cards: Cards): boolean =>
    {
        if (cards.size() !== this.cards.size()) {
            return false;
        }

        /**
         * This only implements straight bomb checks, because it's otherwise covered by
         * @see Kind
         */
        if (!cards.isStraightBomb()) {
            return false;
        }

        return cards.max().getValue() > this.cards.max().getValue();
    }
}
