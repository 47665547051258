import Any from './Any';
import Cards from '../models/Cards';

export default class Start extends Any
{
    public allows(cards: Cards): boolean
    {
        if (cards.isEmpty()) {
            return false;
        }

        if (cards.min().getValue() !== 0) {
            return false;
        }

        return super.allows(cards);
    }
}
