import React from 'react';
import Game from '../models/Game';
import { Play as Model } from '../models/Game';
import Card from './Card';
import Avatar from './Avatar'
import './History.css';

interface Props
{
    game: Game|undefined;
}

interface Plays
{
    round: Model[];
}

const Round: React.FC<Plays> = ({ round }) =>
(
    <div className="round">
        {round.map((play, index) => <Play key={index} {...play} />)}
    </div>
);

const Play: React.FC<Model> = play =>
(
    <div className="play">
        <div className="player"><Avatar player={play.player} />{play.player.username}</div>
        <div className="cards">
            {play.cards.isEmpty() ? "Knocked" : play.cards.toArray().map((card, index) => <Card key={index} {...card}/>)}
        </div>
    </div>
);

const History: React.FC<Props> = ({ game }) =>
(
    <div className="history">
        {game?.getHistory().map((round, index) => <Round key={index} round={round} />)}
    </div>
);

export default History;
