import { Action } from './index';

export const SELECT_CARD = 'SELECT_CARD';
export const TOGGLE_BOT = 'TOGGLE_BOT';

interface State
{
    selected: number[]
    bot: boolean
}

const initialState = {
    selected: [] as number[],
    bot: false,
};

function setBot(state: State, bot: boolean): State
{
    return {
        ...state,
        bot,
    };
}

// this reducer is for when selected cards change, to update the play button state
export default function(state = initialState, action: Action): State
{
    if (action.type === TOGGLE_BOT) {
        return setBot(state, action.payload);
    }

    if (action.type !== SELECT_CARD) {
        return state;
    }

    const card = action.payload as number;

    // remove card if it exists
    if (state.selected.includes(card)) {
        const selected = state.selected.filter(value => card !== value);
        return {
            ...state,
            selected,
        };
    }

    // else add the new card
    const selected = [...state.selected, card];
    selected.sort();

    return {
        ...state,
        selected,
    };
}
