import Player from '../models/Player';

export default class Knock extends Error
{
    public readonly player: Player;

    constructor(player: Player)
    {
        super();
        this.name = "Knock";
        this.player = player;
    }
}
