import { Action } from './index';

export const BOMB_PLAYED = 'BOMB_PLAYED'

export default function(state = { count: 0 }, action: Action) {
    if (action.type !== BOMB_PLAYED) {
        return state;
    }

    const count = state.count + 1

    return {
        ...state,
        count,
    };
}
