import Cookies from 'js-cookie';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from '../redux/reducers';
import { USERS_FETCHED } from '../redux/reducers/users';
import { API_TOKEN } from '../utils/constants';
import Login from './Login';
import TableSelector from './TableSelector';

const Lobby: React.FC = () => {
    const dispatch = useDispatch();
    const [token, setToken] = useState(Cookies.get(API_TOKEN));

    useEffect(() => {
        (async () => {
            const url = process.env.REACT_APP_USERS_ENDPOINT as string;
            const users = await (await fetch(url)).json();
            dispatch({ type: USERS_FETCHED, payload: users } as Action);
        })();
    }, [dispatch]);

    if (!token) {
        return <Login setToken={setToken} />
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
        auth: {
            headers: {
                authorization: "Bearer " + token,
            },
        },
    })

    return <TableSelector pusher={pusher} />
}

export default Lobby;
