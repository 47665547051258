import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Pusher, { PresenceChannel } from 'pusher-js';
import { hash } from '../utils/Crypto';
import Loader from './Loader';
import Table, { Props as TableProps } from './Table';
import ActiveTables from './ActiveTables';
import Model from '../models/Table';
import Player from '../models/Player';
import Member from '../pusher/models/Member';
import Opponent from '../models/Opponent';

const TableSelector: React.FC<{pusher: Pusher}> = ({ pusher }) => {
    const { name } = useParams();
    const history = useHistory();
    const [tableName, setTableName] = useState<string>(name || '');
    const [tableProps, setTableProps] = useState<TableProps>();
    const [loading, setLoading] = useState(false);

    const join = (event?: React.FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        if (!tableName) {
            return;
        }

        setLoading(true);

        const tableChannel = pusher.subscribe('presence-table-' + hash(tableName)) as PresenceChannel;
        tableChannel.bind('pusher:subscription_succeeded', () => {
            const me: Member = tableChannel.members.me;
            const user = new Player({ id: me.id, ...me.info });
            const table = new Model(tableName, user);

            tableChannel.members.each((member: Member) => {
                const player = (member.id === user.id)
                    ? user
                    : new Opponent({ id: member.id, ...member.info });

                table.join(player);
            });
            table.restore();
            history.push("/tables/" + tableName);
            setTableProps({ table, pusher });
            setLoading(false);
        });
    }

    useEffect(join, []);

    if (loading) {
        return <Loader />
    }

    if (tableProps) {
        return <Table {...tableProps} />
    }

    return (
        <div className="lobby">
            <form onSubmit={join}>
                <label htmlFor="table">Table</label>
                <input id="table" placeholder="name" value={tableName} onChange={e => setTableName(e.target.value)} autoFocus={!tableName} required />
                <button type="submit" className="join">Join</button>
            </form>
            <ActiveTables pusher={pusher} />
        </div>
    )
}

export default TableSelector;
