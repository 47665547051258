import Player from './Player';
import Cards from './Cards';
import Rule from '../rules/Rule';
import Knock from '../exceptions/Knock';

export default class Opponent extends Player
{
    public cards: Cards = new Cards();

    public play = (rule: Rule): Cards =>
    {
        const cards = this.cards;

        if (rule.allows(cards)) {
            this.pop()
            return cards;
        }

        throw new Knock(this);
    }

    protected pop = (): void =>
    {
        let count = this.cards.size();
        const cards = new Cards();
        const keys = this.hand.keys();

        while (count--) {
            cards.push(this.hand.get(keys[count]));
        }

        this.hand.delete(cards);
        this.cards = new Cards();
    }
}
