import React from 'react';
import { useSelector } from 'react-redux';
import Player from '../models/Player';
import './Hand.css';
import { User } from '../redux/reducers/users';

interface Props
{
    player: Player;
}

const WinCounter: React.FC<Props> = ({ player }) =>
{
    const user: User = useSelector((state: any) => state.users[player.id]);

    return <div className="wins"><span>{player.wins || ""}</span><span>{user?.wins || ""}</span></div>;
}

export default WinCounter
