import React from 'react';
import Player from '../models/Player';
import './Hand.css';

interface Props
{
    player?: Player;
}

const Avatar: React.FC<Props> = ({ player }) =>
{
    if (!player?.avatar_url) {
        return <></>
    }

    return <img
        className="avatar"
        src={player.avatar_url}
        alt={"avatar for " + player.username}
    />
}

export default Avatar
