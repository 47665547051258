import Cards from '../models/Cards';
import Rule from './Rule';

export default class Kind implements Rule
{
    protected cards: Cards;

    public constructor(cards: Cards)
    {
        this.cards = cards;
    }

    public allows = (cards: Cards): boolean =>
    {
        if (cards.isEmpty()) {
            return false;
        }

        if (this.isBombableBy(cards)) {
            return true;
        }

        if (cards.size() !== this.cards.size()) {
            return false;
        }

        if (!cards.isKind()) {
            return false;
        }

        return cards.max().getValue() > this.cards.max().getValue();
    }

    protected isBombableBy = (cards: Cards): boolean =>
    {
        if (!this.cards.isBombable()) {
            return false;
        }

        if (cards.isStraightBomb()) {
            return ((this.cards.size() + 2) * 2) === cards.size();
        }

        if (cards.isKindBomb()) {
            return (this.cards.size() + 3) === cards.size();
        }

        return false;
    }
}
