import Card from './Card';
import { makeCard } from './Deck';

export default class Cards
{
    protected store: Map<number,Card> = new Map();

    public constructor(cards: Card[] = [])
    {
        cards.forEach(card => {
            this.push(card);
        })
    }

    public size = (): number =>
    {
        return this.store.size;
    }

    public isEmpty = (): boolean =>
    {
        return this.size() === 0;
    }

    public keys = (): number[] =>
    {
        return Array.from( this.store.keys() );
    }

    public toJSON = () =>
    {
        return this.keys();
    }

    public get = (key: number): Card =>
    {
        const card = this.store.get(key);

        if (!card) {
            throw new Error(`Key "${key}" is not in cards.`);
        }

        return card;
    }

    public push = (card: Card): void =>
    {
        this.store.set(card.getValue(), card);
    }

    public delete = (cards: Cards): void =>
    {
        cards.toArray().forEach(card => {
            this.store.delete(card.getValue());
        });
    }

    public min = (): Card =>
    {
        return this.get(Math.min( ...this.keys() ));
    }

    public max = (): Card =>
    {
        return this.get(Math.max( ...this.keys() ));
    }

    public toArray = (): Card[] =>
    {
        return Array.from(this.store.values());
    }

    public selected = (): Cards =>
    {
        const cards = new Cards();

        this.store.forEach(card => {
            if (card.selected) {
                cards.push(card);
            }
        });

        return cards;
    }

    public unselectAll = (): void =>
    {
        this.store.forEach(card => {
            card.select(false);
        });
    }

    public isStraight = (): boolean =>
    {
        const values = this.keys();

        if (values.length < 3) {
            return false;
        }

        let total = this.get(values[0]).rank.value;

        for (let i = 1; i < values.length; i++) {
            let card = this.get(values[i]);

            if (card.rank.value !== ++total) {
                return false;
            }

            if (card.rank.name === "2") {
                return false;
            }
        }

        return true;
    }

    public isKind = (): boolean =>
    {
        const first = this.min();

        for (const card of this.toArray()) {
            if (card.rank.value !== first.rank.value) {
                return false;
            }
        }

        return true;
    }

    public isBomb = (): boolean =>
    {
        if (this.isEmpty()) {
            return false
        }

        if (this.isKindBomb()) {
            return true;
        }

        return this.isStraightBomb();
    }

    /**
     * This is mostly copied from
     * @see isStraight
     */
    public isStraightBomb = (): boolean =>
    {
        const values = this.keys();

        if (values.length < 6) {
            return false;
        }

        if (values.length % 2 !== 0) {
            return false;
        }

        let total = this.get(values[0]).rank.value;

        for (let i = 0; i < values.length; i = i+2) {
            let card = this.get(values[i]);
            let pair = this.get(values[i+1]);

            if (!pair) {
                return false;
            }

            if (card.rank.name === "2") {
                return false;
            }

            if (card.rank.value !== pair.rank.value) {
                return false;
            }

            if (card.rank.value !== total++) {
                return false;
            }
        }

        return true;
    }

    public isKindBomb = (): boolean =>
    {
        if (!this.isKind()) {
            return false;
        }

        return this.size() === 4;
    }

    public isBombable = (): boolean =>
    {
        if (this.isStraight()) {
            return false;
        }

        return this.min().rank.name === "2";
    }
}

export function makeCardsFromValues(values: number[]): Cards
{
    const cards: Card[] = [];

    values.forEach(value => {
        cards.push(makeCard(value));
    });

    return new Cards(cards);
}
