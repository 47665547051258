import Card from './Card';
import { Suits, Ranks } from './Deck';
import Player from './Player';
import Hand from './Cards';

function compare(a: Card, b: Card): number
{
    if (b.rank.value > a.rank.value) {
        return 1;
    }

    if (b.rank.value < a.rank.value) {
        return -1;
    }

    if (b.suit.value === a.suit.value) {
        return 0;
    }

    return b.suit.value > a.suit.value
        ? 1
        : -1;
}

/**
 * @see https://stackoverflow.com/a/2450976/4233593
 */
function shuffle(cards: Card[]): void
{
    let currentIndex = cards.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = cards[currentIndex];
        cards[currentIndex] = cards[randomIndex];
        cards[randomIndex] = temporaryValue;
    }
}

export function trumps(subject: Card, object: Card): boolean
{
    return compare(subject, object) < 1;
}

export function deal(suits: number): Hand[]
{
    const deck: Card[] = [];

    let suitCount = 0;

    for (let suit in Suits) {
        Object.keys(Ranks).forEach(rank => {
            deck.push(new Card(rank, suit));
        })
        if (++suitCount >= suits) {
            break;
        }
    }

    shuffle(deck);

    const hands = [];
	const cardsPerHand = 13;

    while (deck.length) {
        const hand = deck.splice(0, cardsPerHand);
    	hands.push(new Hand(hand));
    }

    return hands;
}

export default class Dealer
{
    public deal(players: Player[]): void
    {
        const hands = deal(players.length);

        for (let i: number = 0; i < players.length; i++) {
            players[i].hand = hands[i];
            players[i].knocked = false;
            players[i].up = hands[i].min().getValue() === 0;
            players[i].winner = false;
            players[i].current = false;
        }
    }
}
