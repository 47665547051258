import { Action } from './index';

export const CARDS_PLAYED = 'CARDS_PLAYED';

const initialState = {
    current: {
        cards: [] as number[],
    },
};

export default function(state = initialState, action: Action)
{
    if (action.type !== CARDS_PLAYED) {
        return state;
    }

    const cards = action.payload as number[];

    return {
        ...state,
        current: {
            cards,
        },
    };
}
