import Card from './Card';
import Cards from './Cards';

export default class HiddenCards extends Cards
{
    public constructor(count: number)
    {
        const cards: Card[] = [];

        while (count--) {
            const card = new Card('', '');
            card.rank.value = -count;
            card.suit.value = -(count % 10);
            cards.push(card);
        }

        super(cards);
    }
}
