import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { getLegacyPassword, hash } from '../utils/Crypto';
import { API_TOKEN } from '../utils/constants';

function createToken(username: string, password: string): string {
    const token = btoa(JSON.stringify({
        idp: "self",
        id: hash(username + password),
        username,
        password,
    }))

    Cookies.set(API_TOKEN, token, { expires: 365, sameSite: 'strict' })

    return token
}

function getRandomUsername(): string {
    const quotes = require('./Loader.json'); // tsk, tsk
    return quotes[Math.floor(Math.random() * quotes.length)].author;
}

const LoginLegacy: React.FC<{ setToken: (token: string) => void }> = ({ setToken }) => {
    const [username, setUsername] = useState(Cookies.get('username') || '');
    const [usernamePlaceholder] = useState(getRandomUsername());
    const [password, setPassword] = useState(getLegacyPassword());
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visiblePasswordIcon, setVisiblePasswordIcon] = useState('👀');
    const [visiblePasswordMessage, setVisiblePasswordMessage] = useState('show password');

    const login = (event?: React.FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        if (!username) {
            return;
        }

        if (!password) {
            return;
        }

        const token = createToken(username, password)
        setToken(token)
    }

    const visiblePasswordHandler = visiblePassword
        ? () => {
            setPasswordFieldType('password')
            setVisiblePassword(false)
            setVisiblePasswordIcon('👀')
            setVisiblePasswordMessage('show password')
        }
        : () => {
            setPasswordFieldType('text')
            setVisiblePassword(true)
            setVisiblePasswordIcon('🙈')
            setVisiblePasswordMessage('hide password')
        }

    return (
        <form onSubmit={login}>
            <label htmlFor="username">Username</label>
            <input
                id="username"
                placeholder={usernamePlaceholder}
                value={username}
                onChange={e => setUsername(e.target.value)}
                pattern="[\x20-\x7E]+"
                title="only regular ASCII characters allowed"
                required
            />
            <div className="password-labels">
                <label htmlFor="password">Password</label>
                <span role="img" title={visiblePasswordMessage} aria-label={visiblePasswordMessage} onClick={visiblePasswordHandler}>{visiblePasswordIcon}</span>
            </div>
            <input
                id="password"
                type={passwordFieldType}
                placeholder="correct horse battery staple"
                value={password}
                onChange={e => setPassword(e.target.value)}
                pattern="[\x20-\x7E]+"
                title="only regular ASCII characters allowed"
                required
            />
            <button type="submit" className="login"><span role="img" aria-label="old man">👴</span> Legacy Login</button>
            {visiblePassword && <span className="user-id">User ID {hash(username + password)}</span>}
        </form>
    )
}

export default LoginLegacy;
