import Player from './Player';
import Rule from '../rules/Rule';
import Knock from '../exceptions/Knock';
import Cards, { makeCardsFromValues } from './Cards';
import Any from '../rules/Any';
import Straight from '../rules/Straight';
import Kind from '../rules/Kind';
import Bomb from '../rules/Bomb';
import Start from '../rules/Start';
import Response from '../pusher/events/restore/Response';
import store from '../redux/store'
import { BOMB_PLAYED } from '../redux/reducers/bombs'

export interface Play
{
    player: Player;
    cards: Cards;
}

export default class Game
{
    public readonly id: string;
    public players: Player[];
    public current: Play;
    protected round: number = 0;
    protected readonly history: Play[][] = [];
    protected currentPlayerIndex: number = 0;
    protected nextPlayerIndex: number = 0;
    protected winner: Player|null = null;

    public constructor(players: Player[], id: string = '')
    {
        this.players = players;
        this.id = id;

        this.players.forEach((player, index) => {
            if (player.up) {
                this.currentPlayerIndex = index;
                this.nextPlayerIndex = index;
            }
        });

        this.current = this.makePlay();
    }

    protected pushHistory = (play: Play): void =>
    {
        if (!(this.round in this.history)) {
            this.history[this.round] = [];
        }

        this.history[this.round].push(play);
    }

    public getHistory = (): Play[][] =>
    {
        const history: Play[][] = [];

        for (let i = this.history.length - 1; i >= 0; i--) {
            history.push(this.history[i].slice().reverse());
        }

        return history;
    }

    public tick = (): void =>
    {
        if (this.winner) {
            return;
        }

        try {
            this.play();
        } catch (error) {
            if (error instanceof Knock) {
                this.handleKnock(error);
            } else {
                throw error;
            }
        } finally {
            this.markNextPlayer();
        }

        this.handleEndGame();
    }

    protected play = (): void =>
    {
        const rule = this.makeRule();
        const index = this.turn();
        const player = this.players[index];
        const cards = player.play(rule);

        if (cards.isBomb()) {
            store.dispatch({ type: BOMB_PLAYED, payload: null })
        }

        const play = this.makePlay(player, cards);
        this.currentPlayerIndex = index;
        this.current = play;
        this.pushHistory(play);
        this.markCurrentPlayer();
    }

    public makeRule = (): Rule =>
    {
        const cards = this.current.cards;

        if (cards.size() === 0) {
            if (this.round === 0) {
                return new Start();
            }

            return new Any();
        }

        if (cards.isStraight()) {
            return new Straight(cards);
        }

        if (cards.isStraightBomb()) {
            return new Bomb(cards);
        }

        // handles single, pairs, 3 of kind, and kind bombs
        return new Kind(cards);
    }

    /**
     * return up player, and then set who the next player is going to be, based on knocks
     */
    protected turn = (): number =>
    {
        const index = this.getAndIncrementNextPlayerIndex();

        let safety = this.players.length;
        while (this.players[this.nextPlayerIndex].knocked) {
            this.getAndIncrementNextPlayerIndex();

            if (safety-- < 0) {
                throw new Error("infinite loop here");
            }
        }

        return index;
    }

    protected getAndIncrementNextPlayerIndex = (): number =>
    {
        const index = this.nextPlayerIndex++;

        if (this.nextPlayerIndex >= this.players.length) {
            this.nextPlayerIndex = 0;
        }

        if (this.players[this.nextPlayerIndex].disconnected) {
            this.players[this.nextPlayerIndex].knocked = true;
        }

        return index;
    }

    protected markNextPlayer = (): void =>
    {
        this.players.forEach((player, index) => {
            if (index === this.nextPlayerIndex) {
                player.up = true;
            } else {
                player.up = false;
            }
        });
    }

    protected clearNextPlayer = (): void =>
    {
        this.players.forEach(player => {
            player.up = false;
        });
    }

    protected markCurrentPlayer = (): void =>
    {
        this.players.forEach((player, index) => {
            if (index === this.currentPlayerIndex) {
                player.current = true;
            } else {
                player.current = false;
            }
        });
    }

    protected makePlay = (player: Player = this.getCurrentPlayer(), cards: Cards = new Cards()): Play =>
    {
        return { player, cards };
    }

    public getCurrentPlayer = (): Player =>
    {
        return this.players[this.currentPlayerIndex];
    }

    public getWinner = (): Player|null =>
    {
        return this.winner;
    }

    public findPlayer = (id: string): Player =>
    {
        return this.players[this.players.findIndex(player => player.id === id)];
    }

    protected handleEndGame = (): void =>
    {
        if (!this.getCurrentPlayer().isOutOfCards()) {
            return;
        }

        this.winner = this.getCurrentPlayer();
        this.getCurrentPlayer().winner = true;
        this.getCurrentPlayer().wins++;
        this.clearNextPlayer();
    }

    protected handleKnock = (error: Knock): void =>
    {
        error.player.knocked = true;
        this.pushHistory({ player: error.player, cards: new Cards() });

        if (this.stillHasUnknockedPlayers()) {
            return;
        }

        this.round++;
        this.current = this.makePlay(this.getCurrentPlayer());
        this.players.forEach(player => {
            player.current = false;
            player.knocked = false;
        });
        this.getCurrentPlayer().up = true;
    }

    protected stillHasUnknockedPlayers = (): boolean =>
    {
        for (let i = 0; i < this.players.length; i++) {
            if (i !== this.currentPlayerIndex && !this.players[i].knocked) {
                return true;
            }
        }

        return false;
    }

    public sync = (event: Response): void =>
    {
        const {
            current,
            round,
            currentPlayerIndex,
            nextPlayerIndex,
            winner_id,
        } = event.table.game;

        this.round = round;
        this.currentPlayerIndex = currentPlayerIndex;
        this.nextPlayerIndex = nextPlayerIndex;
        this.winner = (winner_id)
            ? this.findPlayer(winner_id)
            : null;
        this.current = this.makePlayFromPrimitives(current.player.id, current.cards);
        this.reconstructHistory(event);
    }

    protected reconstructHistory = (event: Response): void =>
    {
        this.history.length = 0;

        event.table.game.history.forEach(r => {
            const round: Play[] = [];
            r.forEach(play => {
                round.push(this.makePlayFromPrimitives(play.player.id, play.cards));
            });
            this.history.push(round);
        });
    }

    protected makePlayFromPrimitives = (playerId: string, cards: number[]): Play =>
    {
        return this.makePlay(this.findPlayer(playerId), makeCardsFromValues(cards));
    }
}
