import React from 'react'
import Cookies from 'js-cookie'
import { API_TOKEN } from '../utils/constants'

function logout() {
    Cookies.remove(API_TOKEN)
    window.location.href = '/'
}

const LogoutButton: React.FC = () => {
    return (
        <button className="logout" onClick={logout}>
            <span role="img" aria-label="logout">✌️</span>
            Logout
        </button>
    )
}

export default LogoutButton;
