import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const DarkModeButton: React.FC = () => {
    const [darkMode, setDarkMode] = useState<boolean>(Cookies.get('dark-mode') === 'on');

    useEffect(() => {
        document.body.classList.toggle('dark', darkMode);
    }, [darkMode]);

    return (
        <button className="dark-mode" onClick={() => {
            Cookies.set('dark-mode', !darkMode ? 'on' : 'off', { expires: 365, sameSite: 'strict' });
            setDarkMode(!darkMode)
        }}>
            {darkMode
                ? <span><span role="img" aria-label="enable light mode">😎</span> Light Mode</span>
                : <span><span role="img" aria-label="enable dark mode">🖤</span> Dark Mode</span>
            }
        </button>
    )
}

export default DarkModeButton;
