import React from 'react';
import Card from './Card';
import WinCounter from './WinCounter';
import Player from '../models/Player';
import Avatar from './Avatar'
import Opponent from '../models/Opponent';
import './Hand.css';

interface Props
{
    player: Player;
}

const Hand: React.FC<Props> = ({ player }) =>
{
    let classes = "hand";

    if (player.knocked) {
        classes += " knocked";
    }

    if (player.up) {
        classes += " up";
    }

    if (player.winner) {
        classes += " winner";
    }

    if (player.disconnected) {
        classes += " disconnected";
    }

    if (player.bot) {
        classes += " bot";
    }

    if (!(player instanceof Opponent)) {
        classes += " user";
    }

    const playerNameClass = (player.up && (!(player instanceof Opponent)))
        ? "player pulsate-fwd"
        : "player";

    return (
        <div className={classes}>
            <h2 className={playerNameClass}><Avatar player={player}/> <span>{player.username}</span> <WinCounter player={player} /></h2>
            <div className="cards">
                {player.hand.toArray().map(card => <Card key={card.getValue()} {...card} />)}
            </div>
        </div>
    )
}

export default Hand
