import React, { useEffect, useState, useCallback } from 'react';
import Pusher from 'pusher-js';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../models/Table';
import Player from '../models/Player';
import './PlayButton.css';
import Play from '../pusher/events/Play';
import { CARDS_PLAYED } from '../redux/reducers/table';

const SECONDS_TO_PLAY = 42;

interface Props {
    table: Table;
    pusher: Pusher;
    setPlayers: (players: Player[]) => void;
}

function makeClassName(table: Table): string {
    const user = table.user;

    if (!user.up) {
        return "waiting"
    }

    if (user.up && table.game?.current.cards.isEmpty() && user.hand.selected().isEmpty()) {
        return "pick"
    }

    if (table.game?.makeRule().allows(user.hand.selected())) {
        return "play";
    }

    return "knock";
}

const PlayButton: React.FC<Props> = ({ pusher, table, setPlayers }) => {
    const dispatch = useDispatch();
    useSelector((state: any) => state.user.selected);
    const className = makeClassName(table);
    const [seconds, setSeconds] = useState(SECONDS_TO_PLAY);

    const playable = ["play", "knock"].includes(className);

    const play = useCallback(() => {
        const cards = table.user.hand.selected().keys();
        const play: Play = {
            cards,
            game_id: table.game?.id || '🙃',
        }
        pusher.channel('presence-table-' + table.nameHash).trigger('client-play', play);
        table.tick();
        setPlayers([...table.players]);
        dispatch({ type: CARDS_PLAYED, payload: cards });
        setSeconds(SECONDS_TO_PLAY);
    }, [pusher, table, setPlayers, dispatch, setSeconds]);

    useEffect(() => {
        if (!playable) {
            return;
        }

        const interval = setInterval(() => {
            setSeconds(seconds - 1);
            if (seconds <= 0) {
                play();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds, setSeconds, playable, play]);

    useEffect(() => {
        if (table.user.up) {
            if (window.navigator.vibrate) {
                window.navigator.vibrate(100);
            }
        }
    }, [table.user.up]);

    return (
        <button disabled={!playable} className={className} onClick={play}>{playable && seconds}</button>
    );
}

export default PlayButton;
