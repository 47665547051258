import { Rank, Suits, Suit, Ranks } from './Deck';

export default class Card
{
    public readonly rank: Rank;
    public readonly suit: Suit;
    public selected: boolean = false;

    public constructor(rank: string, suit: string)
    {
        this.rank = { name: rank, value: Ranks[rank] };
        this.suit = { name: suit, value: Suits[suit] };
    }

    public getValue = (): number =>
    {
        return (this.rank.value * 10) + this.suit.value;
    }

    public select = (selected: boolean|null = null): boolean =>
    {
        if (selected === null) {
            return this.selected = !this.selected;
        }

        return this.selected = selected;
    }
}
