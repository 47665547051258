import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Model from '../models/Card';
import { SELECT_CARD } from '../redux/reducers/user';
import './Card.css';

const Card: React.FC<Model> = ( card ) =>
{
    const dispatch = useDispatch();
    const cards: number[] = useSelector((state: any) => state.table.current.cards);
    const bot = useSelector((state: any) => state.user.bot);
    const [selected, setSelected] = useState(card.selected);
    const [introAnimationClassName, setIntroAnimationClassName] = useState('');

    useEffect(() => {
        setSelected(card.selected);
    }, [bot, card.selected]);

    useEffect(() => {
        if (cards.includes(card.getValue())) {
            setIntroAnimationClassName('swirl-in-fwd');
        }
        const timer = setTimeout(() => {
            setIntroAnimationClassName('');
        }, 1000);
        return () => clearTimeout(timer);
    }, [cards, card]);

    let classes = `card ${card.suit.name} ${introAnimationClassName}`;

    if (selected) {
        classes += " selected";
    }

    return (
        <div className={classes} onClick={() => {
            setSelected(card.select());
            dispatch({ type: SELECT_CARD, payload: card.getValue() });
        }}>
            <div className="rank">{card.rank.name}</div>
            <div className="suit">{card.suit.name}</div>
        </div>
    );
}

export default Card
