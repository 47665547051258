import Cards from '../models/Cards';
import Rule from './Rule';

export default class Straight implements Rule
{
    protected cards: Cards;

    public constructor(cards: Cards)
    {
        this.cards = cards;
    }

    public allows = (cards: Cards): boolean =>
    {
        if (cards.size() !== this.cards.size()) {
            return false;
        }

        if (!cards.isStraight()) {
            return false;
        }

        return cards.max().getValue() > this.cards.max().getValue();
    }
}
