import Cards from '../models/Cards';
import Rule from './Rule';

export default class Any implements Rule
{
    public allows(cards: Cards): boolean
    {
        if (cards.size() === 0) {
            return false;
        }

        if (cards.isStraight()) {
            return true;
        }

        if (cards.isStraightBomb()) {
            return true;
        }

        return cards.isKind();
    }
}
